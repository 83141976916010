
// for import component or other scss files

* {
  box-sizing: border-box;
}

@import "tailwind_out.css";

@import "@trustana/pattern-library/index.css";

@import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
